import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { join } from 'path';
import Img from 'gatsby-image';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import '../styles/slick.css';

import SEO from '../components/SEO';
import Breadcrumbs from '../components/Breadcrumbs';

import { remark } from 'remark';
import recommended from 'remark-preset-lint-recommended';
import remarkHtml from 'remark-html';

const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
};

function ProductPage({
  data: {
    site: {
      siteMetadata: { siteUrl },
    },
    product: { frontmatter: product },
    category: categoryField
  },
  location,
}) {
  const category = categoryField?.frontmatter
  const { variantId } = queryString.parse(location.search);
  const variants = product.variations;
  const [firstVariant] = variants;
  const [activeVariantId, setActiveVariantId] = useState(
    variantId || firstVariant.id
  );
  const productImage = product.images[0];

  const activeVariant = variants.find(
    variant => variant.id === activeVariantId
  );
  const paths = [];
  if (category) {
    paths.push({
      name: category.name,
      to: join('/collections', category.url, '/'),
    });
  }
  paths.push({ name: product.name, to: location.pathname });

  // useEffect(() => {
  //   navigate(`?variantId=${activeVariantId}`, { replace: true });
  // }, [activeVariantId]);

  if (!product) return null;

  return (
    <React.Fragment>
      <SEO
        pageTitle={product.name}
        image={productImage.childImageSharp.fluid.src}
      />
      <Helmet>
        <link rel="canonical" href={`${siteUrl}/${product.url_key}/`} />
      </Helmet>
      <Breadcrumbs paths={paths} />
      <div className="lg:flex -mx-6">
        <div className="mb-8 px-6 md:mb-0 lg:w-1/2">
          <div className="w-full overflow-hidden relative">
            <Slider {...settings} className="overflow-hidden">
              {product.images.map((image, index) => (
                <div key={index}>
                  <Img
                    style={{ maxHeight: 560 }}
                    className="rounded-lg"
                    fluid={image.childImageSharp.fluid}
                    alt={product.name}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    title={product.name}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>

        <div className="px-6 md:py-3 lg:w-1/2">
          <h1 className="font-bold text-3xl md:text-6xl mb-3 text-primary leading-tight">
            {product.name}
          </h1>

          <div className="mb-6">
            <p className="font-semibold text-2xl text-slategray">
              {activeVariant && activeVariant.formattedPrice}
            </p>
          </div>

          {product.description && (
            <div
              className="mb-6"
              dangerouslySetInnerHTML={{ __html: remark().use(recommended).use(remarkHtml).processSync(product.description).toString() }}
            />
          )}
          <div className="md:flex md:flex-wrap -mx-3">
            {variants.length > 1 ? (
              <div className="md:w-3/4 px-3 mb-6">
                <label
                  className="block text-sm font-bold tracking-widest uppercase mb-2 text-slategray"
                  htmlFor="style"
                >
                  Opcijas
                </label>

                <div className="relative">
                  <select
                    id="style"
                    value={activeVariantId}
                    onChange={({ target: { value } }) =>
                      setActiveVariantId(value)
                    }
                    className="block appearance-none w-full bg-gainsboro border-2 border-gainsboro focus:border-slategray px-4 py-3 pr-8 focus:outline-none focus:bg-white text-slategray focus:text-slategray rounded-lg"
                  >
                    {variants.map((variant, index) => (
                      <option key={index} value={variant.id}>
                        {variant.name}
                      </option>
                    ))}
                  </select>

                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-slategray">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {/* <div>
            <a
              href={process.env.GATSBY_WHATSAPP_LINK}
              className="text-center inline-block"
            >
              <img
                className="mx-auto"
                alt="Sazinies WhatsApp"
                style={{ height: 50 }}
                src={'/WhatsApp.png'}
              />
              <p className="text-lightgray">Sazinies WhatsApp</p>
            </a>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
}

export const pageQuery = graphql`
  query ProductQuery($url_key: String!, $categoryId: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    product: markdownRemark(frontmatter: {url_key: {eq: $url_key}}) {
      frontmatter {
        name
        url_key
        description
        images {
          childImageSharp {
            fluid(maxWidth: 560) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        variations {
          price
          id
          name
          formattedPrice
          quantity
          infinite_quantity
        }
      }
    }
    category: markdownRemark(frontmatter: {url: {eq: $categoryId}}) {
      frontmatter {
        name
        url
      }
    }
  }
`;

export default ProductPage;
